import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalStop({ onCancel, onConfirm, visible }) {
  return (
    <Modal backdrop="static" show={visible} onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          If you give up now you won&apos;t be able to carry on from this point.
          You&apos;ll have to start all over again.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCancel}>No, keep playing</Button>
        <Button variant="success" onClick={onConfirm}>Yes, give up</Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalStop.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ModalStop.defaultProps = {
  visible: false,
};

export default ModalStop;
